import React, { Component } from "react";
import {
  Card,
  Layout,
  List,
  TextContainer,
} from "@shopify/polaris";

import merchantIdScreenShot from "./assets/ShareASaleMerchantId.png";
import catSubcatScreenShot from "./assets/ShareASaleCatSubCat.png";
import catDropDownScreenShot from "./assets/ShareASaleCategoryDropdown.png";

export default class Intro extends Component {
  render() {
    return (
      <Layout.Section>
        <Card>
          <Card.Header title="1) ShareASale Merchant Id"></Card.Header>
          <Card.Section>
            <p>
              Log in to your ShareASale account and get your merchant ID. Enter
              it into the merchant ID field.
            </p>
            <p>
              <img
                src={merchantIdScreenShot}
                alt="ShareASale MerchantId screen shot"
              />
            </p>
          </Card.Section>
          <Card.Header title="2) Default Category and Subcategory Codes"></Card.Header>
          <Card.Section>
            <p>
              Choose your default Category and Subcategory Codes and enter them
              in the boxes.
            </p>
            <p style={{ display: "flex", alignItems: "flex-start" }}>
              <img
                src={catSubcatScreenShot}
                alt="Categories and Subcategories screen shot"
                style={{ marginRight: "1rem" }}
              />
              <img
                src={catDropDownScreenShot}
                alt="Category Dropdown screen shot"
              />
            </p>
          </Card.Section>
          <Card.Header title="3) Alternate Category and Subcategory Codes"></Card.Header>
          <Card.Section>
            <p>
              If there are any product types you have created in Shopify that
              should get alternate category and subcategory codes, enter them.
            </p>
          </Card.Section>
          <Card.Header title="4) Set up ShareASale FTP"></Card.Header>
          <Card.Section>
            <p>
              Create a support ticket letting ShareASale know that you wish to
              FTP your product file and they will send your credentials. Enter
              them in the boxes provided.
            </p>
          </Card.Section>
          <Card.Header title="5) Run ShareASale Export"></Card.Header>
          <Card.Section>
            <TextContainer spacing="loose">
              <p>
                Save Changes and run. You should get an email in a few minutes
                letting you know that export is complete and being sent to
                ShareASale.
              </p>
              <List type="bulleted">
                <List.Item>
                  Check the “Last Export Results” to make sure the result was
                  “[Success]”.
                </List.Item>
                <List.Item>
                  Log into ShareASale to verify that the new file was delivered
                  and processed successfully.
                </List.Item>
                <List.Item>
                  If all is well, click “Run Daily” and choose the hour and
                  minute you would like to start the export process each day.
                </List.Item>
              </List>
            </TextContainer>
          </Card.Section>
        </Card>
      </Layout.Section>
    );
  }
}
