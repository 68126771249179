import React, { Component } from "react";
import { FormLayout, TextField, Button } from "@shopify/polaris";

export default class CategoryOverride extends Component {
  render() {
    const {
      productType,
      category,
      subCategory,
      handleOverrideChange,
      idx,
      deletePco,
    } = this.props;
    const isEmpty =
      productType.trim().length < 1 &&
      category.trim().length < 1 &&
      subCategory.trim().length < 1 &&
      idx === 0;
    const delButton = isEmpty ? (
      ""
    ) : (
      <div style={{ paddingTop: "1.5rem" }}>
        <Button
          destructive
          onClick={(evt) => {
            deletePco(evt, idx);
          }}
        >
          Delete
        </Button>
      </div>
    );
    return (
      <FormLayout>
        <FormLayout.Group condensed>
          <TextField
            label="Product Type"
            onChange={(evt) => {
              handleOverrideChange(evt, "productType", idx);
            }}
            type="text"
            value={productType}
          />
          <TextField
            label="Category"
            onChange={(evt) => {
              handleOverrideChange(evt, "category", idx);
            }}
            type="text"
            value={category}
          />
          <TextField
            label="Subcategory"
            onChange={(evt) => {
              handleOverrideChange(evt, "subCategory", idx);
            }}
            type="text"
            value={subCategory}
          />
          {delButton}
        </FormLayout.Group>
      </FormLayout>
    );
  }
}
