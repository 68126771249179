import React, { Component } from "react";
import {
  FormLayout,
  Heading,
  Modal,
  TextContainer,
  TextField,
} from "@shopify/polaris";

export default class ContactModal extends Component {
  render() {
    const { toggleModal, supportSubmit, modalActive, supportSubmitted, updateSupportMessage, supportMessage, supportError } = this.props;
    return (
      <Modal
        open={modalActive}
        onClose={toggleModal}
        title="Contact Support"
        primaryAction={{
          content: "Submit",
          onAction: supportSubmit,
        }}
        secondaryActions={[
          {
            content: "Close",
            onAction: toggleModal,
          },
        ]}
      >
        <Modal.Section>
          {supportSubmitted ? (
            <TextContainer>
              <Heading>Thank you</Heading>
              <p>We will be in touch soon.</p>
            </TextContainer>
          ) : (
            <FormLayout>
              <TextField
                label="Message"
                value={supportMessage}
                onChange={updateSupportMessage}
                multiline={6}
                placeholder="Describe your issue"
                error={supportError}
              />
            </FormLayout>
          )}
        </Modal.Section>
      </Modal>
    );
  }
}
