import React, { Component } from "react";
import {
  Banner,
  Card,
  DisplayText,
  Frame,
  Layout,
  Link,
  Page,
} from "@shopify/polaris";
import { CircleAlertMajor } from "@shopify/polaris-icons";

export default class ErrorPage extends Component {
  constructor(props) {
    super(props);
    const { appName } = props;
    this.state = {
      errorMessage:
        `There was an error installing the ${appName} app from Virid`,
    };
  }
  componentDidMount() {
    const searchParams = new URL(document.location).searchParams;
    const message = decodeURIComponent(searchParams.get("message"));
    this.setState({ errorMessage: typeof message !== "undefined" && message !== "null" ? message : "no shop" });
  }
  render() {
    const { errorMessage } = this.state;

    const errorComponent =
      errorMessage === "no shop" ? (
        <DisplayText size="small">
          This app can only be run within Shopify. Get started with Shopify -{" "}
          <Link url="https://www.shopify.com/?ref=virid">sign up now</Link>!
        </DisplayText>
      ) : (
        <DisplayText size="small">{errorMessage}</DisplayText>
      );

    return (
      <Frame>
        <Page>
          <Layout>
            <Layout.Section>
              <Banner
                icon={CircleAlertMajor}
                status="critical"
                title="Installation could not continue"
              ></Banner>
              <Card sectioned>{errorComponent}</Card>
            </Layout.Section>
          </Layout>
        </Page>
      </Frame>
    );
  }
}
