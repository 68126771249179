import React, { Fragment, Component } from "react";
import fetch from "isomorphic-fetch";
import {
  Button,
  Card,
  Checkbox,
  FooterHelp,
  FormLayout,
  Layout,
  Page,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  TextField,
} from "@shopify/polaris";

import ContactModal from "../../components/contact-modal";
import Unverified from "../../components/unverified";
import NotificationBanner from "../../components/notification-banner";
import Intro from "./intro";

import { getInitialData } from "../../utils";
import CategoryOverride from "./CategoryOverride";

const hours = [],
  minutes = [];

for (let i = 0; i < 24; i++) {
  hours.push(i < 10 ? "0" + i : "" + i);
}
for (let i = 0; i < 60; i++) {
  minutes.push(i < 10 ? "0" + i : "" + i);
}

const dateFixer = (dateString) => {
  if (!dateString) {
    return false;
  }
  const goodParts = dateString.split("T")[0];
  const gpSplit = goodParts.split("-");
  return `${gpSplit[1]}-${gpSplit[2]}-${gpSplit[0]}`;
};

const emptyPcos = [{ productType: "", category: "", subCategory: "" }];
const REQUIRED_FIELDS = [
  "email",
  "merchantId",
  "category",
  "subCategory",
  "host",
  "username",
  "password",
];

export default class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appMessage: "",
      category: {
        error: "",
        value: "",
      },
      categoryOverrides: emptyPcos,
      email: {
        error: "",
        value: "",
      },
      host: {
        error: "",
        value: "",
      },
      includeOOSProducts: false,
      isSuccess: false,
      loading: true,
      merchantId: {
        error: "",
        value: "",
      },
      modalActive: false,
      notifyOnComplete: false,
      password: {
        error: "",
        value: "",
      },
      runDaily: false,
      runHour: "00",
      runMinutes: "00",
      subCategory: {
        error: "",
        value: "",
      },
      submitErrorMessage: `Failed to submit form: please double check your info and try again.`,
      submitFail: false,
      submitSuccess: false,
      supportError: "",
      supportMessage: "",
      supportSubmitted: false,
      username: {
        error: "",
        value: "",
      },
      verified: false,
      enable: false,
      showIntro: false,
      useSFTP: true,
    };
  }

  componentDidMount() {
    getInitialData(
      "shareasale-app",
      "https://shareasale.marketagility.com"
    ).then((data) => {
      if (data.isSuccess) {
        this.makeTheDataFriendly(data.appSettings);
      } else {
        // handle verify errors
        this.setState({
          ...this.state,
          loading: false,
          isSuccess: false,
          verified: false,
          enable: false,
          errorCode: data.errorCode,
          redirectUrl: data.redirectUrl,
        });
      }
    });
  }

  makeTheDataFriendly = (data) => {
    data.categoryOverrides =
      data.categoryOverrides && data.categoryOverrides.length > 0
        ? data.categoryOverrides
        : emptyPcos;
    const keys = Object.keys(data);
    keys.forEach((key) => {
      data[key] = data[key] === null ? "" : data[key];
    });
    REQUIRED_FIELDS.forEach((field) => {
      const value = data[field];
      data[field] = {
        value: value,
      };
    });
    const magic = Object.assign(this.state, data);
    this.setState({
      ...magic,
      loading: false,
      isSuccess: true,
      verified: true,
      enable: true,
      lastSendDate: dateFixer(data.lastSendDate),
    });
  };

  handleChange = (field) => {
    let errors = 0;
    return (newValue) => {
      if (REQUIRED_FIELDS.indexOf(field) >= 0) {
        if (!newValue || newValue.trim().length < 1) {
          newValue = { error: "This field is required." };
          errors++;
          if (field === "email" && !this.state.notifyOnComplete) {
            newValue = { value: "", error: "" };
            errors--;
          }
        } else {
          newValue = { value: newValue };
        }
      }
      this.setState({
        ...this.state,
        [field]: newValue,
        enable: errors < 1,
      });
    };
  };

  handleOverrideChange = (newValue, field, idx) => {
    const pcos = this.state.categoryOverrides;
    const thePco = pcos[idx];
    thePco[field] = newValue;
    this.setState({
      ...this.state,
      categoryOverrides: pcos,
    });
  };

  deletePco = (evt, idx) => {
    const pcos = this.state.categoryOverrides;
    pcos.splice(idx, 1);
    this.setState({
      ...this.state,
      categoryOverrides: pcos.length > 0 ? pcos : emptyPcos,
    });
  };

  handleRunDailyChange = (newChecked) => {
    this.setState({ ...this.state, runDaily: newChecked });
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    this.setState({
      ...this.state,
      submitFail: false,
      submitSuccess: false,
    });
    this.submitData();
  };

  addAnotherOverride = () => {
    const pcos = this.state.categoryOverrides;
    const lastGuy = pcos[pcos.length - 1];
    let isEmpty = false;
    for (let key in lastGuy) {
      if (!key || lastGuy[key].trim().length < 1) {
        isEmpty = true;
        break;
      }
    }
    if (!isEmpty) {
      pcos.push({
        productType: "",
        category: "",
        subCategory: "",
      });
      this.setState({
        ...this.state,
        categoryOverrides: pcos,
      });
    }
  };

  toggleIntro = () => {
    this.setState((state) => {
      return { ...this.state, showIntro: !state.showIntro };
    });
  };

  submitData = () => {
    const url =
      "https://shareasale.marketagility.com/api/run?shop=" +
      this.state.shopname;

    const dataModel = {
      category: this.state.category.value,
      categoryOverrides: this.state.categoryOverrides,
      email: this.state.email.value,
      host: this.state.host.value,
      includeOOSProducts: this.state.includeOOSProducts,
      lastSendDate: this.state.lastSendDate,
      lastSendResult: this.state.lastSendResult,
      merchantId: this.state.merchantId.value,
      message: this.state.message,
      nextRun: this.state.nextRun,
      password: this.state.password.value,
      runDaily: this.state.runDaily,
      runHour: this.state.runHour,
      runMinutes: this.state.runMinutes,
      shopId: this.state.shopId,
      shopname: this.state.shopname,
      subCategory: this.state.subCategory.value,
      username: this.state.username.value,
      useSFTP: this.state.useSFTP
    };

    const postSuccess = (response) => {
      if (response.ok) {
        this.setState({
          submitSuccess: true,
          submitFail: false,
        });
      } else {
        this.setState({
          submitSuccess: false,
          submitFail: true,
        });
      }
      window.scrollTo({ top: 0, left: 0 });
    };

    const postFail = (error) => {
      console.error("post failed: ", error);
      this.setState({
        submitSuccess: false,
        submitFail: true,
      });
    };

    const postData = () => {
      return fetch(url, {
        cache: "no-cache",
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataModel),
      });
    };

    postData().then(postSuccess).catch(postFail);
  };

  handleToggleSetting = (setting, isChecked) => {
    let errors = 0;
    let newEmailVal = { ...this.state.email };
    if (setting === "notifyOnComplete" && isChecked) {
      newEmailVal =
        newEmailVal.value && newEmailVal.value.trim().length > 0
          ? newEmailVal
          : { value: newEmailVal.value, error: "This field is required." };
      if (newEmailVal.error && newEmailVal.error.trim().length > 0) {
        errors++;
      }
    } else {
      newEmailVal = { value: this.state.email.value, error: "" };
    }
    this.setState({
      ...this.state,
      [setting]: isChecked,
      email: newEmailVal,
      enable: errors < 1,
    });
  };

  handleChangeTime = (newValue, field) => {
    this.setState({
      ...this.state,
      [field]: newValue,
    });
  };

  toggleModal = () => {
    this.setState({
      ...this.state,
      modalActive: !this.state.modalActive,
      supportMessage: this.state.modalActive ? "" : this.state.supportMessage,
    });
  };

  handleDismissBanner = () => {
    this.setState({
      ...this.state,
      submitSuccess: false,
      submitFail: false,
      submitErrorMessage: `Failed to submit form: please double check your info and try again.`,
    });
  };

  onSupportSubmit = () => {
    const { supportMessage, shop } = this.state;
    const url =
      "https://shareasale.marketagility.com/api/support?shop=" +
      shop +
      "&message=" +
      supportMessage;
    const postSuccess = (response) => {
      if (response.ok) {
        this.setState({
          ...this.state,
          supportSubmitted: true,
        });
        setTimeout(() => {
          this.setState({
            ...this.state,
            supportSubmitted: false,
            supportMessage: "",
            modalActive: false,
            supportError: "",
          });
        }, 1500);
      }
    };
    const postFail = (error) => {
      console.error(error);
      this.setState({
        ...this.state,
        supportError: error,
      });
    };
    const postSupport = () => {
      return fetch(url, {
        cache: "no-cache",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
    };

    if (supportMessage.trim().length < 1) {
      this.setState({
        ...this.state,
        supportError: "Please enter a message",
      });
    } else {
      this.setState({
        ...this.state,
        supportError: "",
      });
      postSupport().then(postSuccess).catch(postFail);
    }
  };

  render() {
    const {
      email,
      notifyOnComplete,
      merchantId,
      category,
      subCategory,
      includeOOSProducts,
      categoryOverrides,
      host,
      username,
      password,
      submitSuccess,
      submitFail,
      submitErrorMessage,
      verified,
      enable,
      runDaily,
      runHour,
      runMinutes,
      modalActive,
      supportMessage,
      supportSubmitted,
      supportError,
      loading,
      errorCode,
      redirectUrl,
      isSuccess,
      lastSendDate,
      lastSendResult,
      showIntro,
      useSFTP,
    } = this.state;
    let notificationBanner = "";
    if (submitSuccess) {
      notificationBanner = (
        <NotificationBanner
          handleOnDismiss={this.handleDismissBanner}
          bannerMessage="Your export settings have been saved!"
          bannerType="success"
        />
      );
    }
    if (submitFail && submitErrorMessage) {
      notificationBanner = (
        <NotificationBanner
          handleOnDismiss={this.handleDismissBanner}
          bannerMessage={submitErrorMessage}
          bannerType="error"
        />
      );
    }
    let noDataComponent = (
      <SkeletonPage primaryAction={false} title={false}>
        <Layout.AnnotatedSection
          title={<SkeletonDisplayText />}
          description={<SkeletonBodyText />}
        >
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.AnnotatedSection>
      </SkeletonPage>
    );
    if (!loading && !isSuccess) {
      noDataComponent = (
        <Unverified
          errorCode={errorCode}
          url={redirectUrl}
          appName="ShareASale"
        />
      );
    }
    const lastResultsDescription = (
      <TextContainer>
        <p>Date: {lastSendDate}</p>
        <p>Result: {lastSendResult}</p>
      </TextContainer>
    );

    const currentScreen = showIntro ? (
      <Intro clickHandler={this.toggleIntro} />
    ) : (
      <Fragment>
        <Layout.AnnotatedSection
          title="Configure ShareASale"
          description="Connect your store to your ShareASale account"
        >
          <Card sectioned title="Merchant Settings">
            <FormLayout>
              <TextField
                error={merchantId.error}
                label="ShareASale Merchant ID"
                onChange={this.handleChange("merchantId")}
                type="text"
                value={merchantId.value}
              />
            </FormLayout>
            <br />
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  error={category.error}
                  label="Default Category"
                  onChange={this.handleChange("category")}
                  value={category.value}
                />
                <TextField
                  error={subCategory.error}
                  label="Default Subcategory"
                  onChange={this.handleChange("subCategory")}
                  value={subCategory.value}
                />
              </FormLayout.Group>
            </FormLayout>
            <br />
            <FormLayout>
              <Checkbox
                label="Include Out Of Stock Products"
                checked={includeOOSProducts}
                onChange={(e) => {
                  this.handleToggleSetting("includeOOSProducts", e);
                }}
              />
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Last Export Results"
          description={lastResultsDescription}
        >
          <Card sectioned title="Category Overrides">
            <FormLayout>
              {categoryOverrides.map((pco, idx) => {
                return (
                  <CategoryOverride
                    productType={pco.productType}
                    category={pco.category}
                    subCategory={pco.subCategory}
                    handleOverrideChange={this.handleOverrideChange}
                    deletePco={this.deletePco}
                    idx={idx}
                    key={idx}
                  />
                );
              })}
              <Button
                disabled={!verified}
                primary
                onClick={this.addAnotherOverride}
              >
                &#43; Add New Override
              </Button>
            </FormLayout>
          </Card>
          <Card sectioned title="FTP Settings">
            <FormLayout>
              <Checkbox
                  label="Use SFTP?"
                  checked={useSFTP}
                  onChange={(e) => {
                    this.handleToggleSetting("useSFTP", e);
                  }}
                />
              <TextField
                error={host.error}
                label="HostName"
                onChange={this.handleChange("host")}
                value={host.value}
              />
              <TextField
                error={username.error}
                label="Username"
                onChange={this.handleChange("username")}
                value={username.value}
              />
              <TextField
                error={password.error}
                type="password"
                label="Password (saved password not displayed, only update as needed)"
                onChange={this.handleChange("password")}
                value={password.value}
              />
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection>
          <Card sectioned title="Run Daily">
            <FormLayout>
              <Checkbox
                label="Run ShareASale Export once a day"
                checked={runDaily}
                onChange={this.handleRunDailyChange}
              />
              {runDaily ? (
                <Fragment>
                  <TextContainer>
                    At what time? (Coordinated Universal Time - UTC)
                  </TextContainer>
                  <FormLayout.Group>
                    <Select
                      label="Hour"
                      options={hours}
                      onChange={(val) => {
                        this.handleChangeTime(val, "runHour");
                      }}
                      value={runHour}
                    />
                    <Select
                      label="Minute"
                      options={minutes}
                      onChange={(val) => {
                        this.handleChangeTime(val, "runMinutes");
                      }}
                      value={runMinutes}
                    />
                  </FormLayout.Group>
                </Fragment>
              ) : (
                ""
              )}
            </FormLayout>
          </Card>
          <Card sectioned title="Notification Email">
            <FormLayout>
              <Checkbox
                label="Notify me when completed"
                checked={notifyOnComplete}
                onChange={(e) => {
                  this.handleToggleSetting("notifyOnComplete", e);
                }}
              />
              <TextField
                error={email.error}
                label="What email should we notify when the process is complete?"
                onChange={this.handleChange("email")}
                placeholder="username@email.com"
                type="email"
                value={email.value}
              />
            </FormLayout>
          </Card>
          <Card sectioned>
            <FormLayout>
              <p>
                Click the button below to save your settings and run the
                ShareASale export.
              </p>
              <Button disabled={!enable} primary onClick={this.handleSubmit}>
                Save Changes &amp; Run
              </Button>
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>
      </Fragment>
    );

    return verified ? (
      <Page
        title="ShareASale Export"
        secondaryActions={
          <Button monochrome onClick={this.toggleIntro}>
            {showIntro ? "Back to the app" : "Getting Started"}
          </Button>
        }
      >
        <Layout>
          <ContactModal
            toggleModal={this.toggleModal}
            supportSubmit={this.onSupportSubmit}
            modalActive={modalActive}
            supportSubmitted={supportSubmitted}
            updateSupportMessage={(newVal) => {
              this.setState({
                ...this.state,
                supportMessage: newVal,
              });
            }}
            supportMessage={supportMessage}
            supportError={supportError}
          />
          {notificationBanner}
          {currentScreen}
          <Layout.Section>
            <FooterHelp>
              Need help?{" "}
              <Button plain onClick={this.toggleModal}>
                Contact us
              </Button>
            </FooterHelp>
          </Layout.Section>
        </Layout>
      </Page>
    ) : (
      noDataComponent
    );
  }
}
