import React, { Component } from "react";
import { Banner, Button, Layout, Page } from "@shopify/polaris";
import { CircleAlertMajor } from "@shopify/polaris-icons";

import PropTypes from "prop-types";

export default class Unverified extends Component {
  // componentDidMount() {
  //   // App was installed, but the token is missing. Send them to the installer
  //   if (this.props.errorCode === "TOKEN_MISSING") {
  //     this.goToAdminPage();
  //   }
  //   // First time Installation. send them to the installer
  //   if (this.props.errorCode === "NOT_INSTALLED") {
  //     this.goToAdminPage();
  //   }
  // }

  goToAdminPage = evt => {
    if (evt) {
      evt.preventDefault();
    }
    window.top.location.href = this.props.url;
  };

  render() {
    const { errorCode, appName } = this.props;
    const declinedCharge = (
      <Banner
        icon={CircleAlertMajor}
        status="critical"
        title="Charges Declined"
      >
        <p>You must approve the subscription charges to use {appName}.</p>
        <br />
        <Button primary onClick={this.goToAdminPage}>
          Approve Subscription
        </Button>
      </Banner>
    );
    const genericError = (
      <Banner
        icon={CircleAlertMajor}
        status="critical"
        title="Application Error"
      >
        <p>
          There has been an error with {appName}. Please click the button below
          to re-install the app. If there are still issues, please reach out to
          the app developer.
        </p>
        <br />
        <Button primary onClick={this.goToAdminPage}>
          Re-install {appName}
        </Button>
      </Banner>
    );
    return (
      <Page>
        <Layout>
          <Layout.Section>
            {errorCode === "CHARGE_DECLINED" ? declinedCharge : genericError}
          </Layout.Section>
        </Layout>
      </Page>
    );
  }
}

Unverified.propTypes = {
  errorCode: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  appName: PropTypes.string.isRequired,
};
