import React, { useState, useEffect, useCallback } from "react";
import fetch from "isomorphic-fetch";
import {
  Button,
  Card,
  Checkbox,
  FooterHelp,
  FormLayout,
  Layout,
  Page,
  RadioButton,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Spinner,
  Stack,
  TextContainer,
  TextField,
} from "@shopify/polaris";

import ContactModal from "../../components/contact-modal";
import Unverified from "../../components/unverified";
import NotificationBanner from "../../components/notification-banner";
import { getInitialData } from "../../utils";
import Intro from "./intro";

const ScrapeShield = props => {
  // state setup
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [unverifiedRedirectUrl, setUnverifiedRedirectUrl] = useState("");
  const [modalIsActive, setModalIsActve] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState(
    `Failed to submit form: please double check your info and try again.`
  );
  const [submitFail, setSubmitFail] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [supportMessage, setSupportMessage] = useState("");
  const [supportSubmitted, setSupportSubmitted] = useState(false);
  const [supportError, setSupportError] = useState("");
  const [showIntro, setShowIntro] = useState(false);
  const [themeOpts, setThemeOpts] = useState([]);
  const [appSettings, setAppSettings] = useState({
    domains: "",
    email: "",
    emailNotification: false,
    generatedScript: "",
    insertScript: "auto",
    redirectUrl: "",
    requestedId: -1,
    scriptCreated: "",
    shop: "",
    shopId: "",
    themes: [],
  });

  const createThemeOpts = themes => {
    const opts = [];
    //put the live theme at the top
    const idx = themes
      .map(t => {
        return t.role;
      })
      .indexOf("main");
    const f = themes.splice(idx, 1)[0];
    themes.splice(0, 0, f);
    themes.forEach(theme => {
      opts.push({
        label:
          theme.role === "main" ? `***LIVE THEME*** ${theme.name}` : theme.name,
        value: parseInt(theme.id, 10),
      });
    });
    // hit that state with the new-new
    setThemeOpts(opts);
  };

  const fixRedirectUrl = oldString => {
    let https = oldString.indexOf("https://");
    let http = oldString.indexOf("http://");
    if (https > -1) {
      return oldString;
    }
    if (http > -1) {
      let domain = oldString.split("http://")[1];
      return `https://${domain}`;
    }
    return `https://${oldString}`;
  };

  useEffect(() => {
    getInitialData(
      "scrapeshield-app",
      "https://scrapeshield.marketagility.com",
      false
    ).then(data => {
      if (data.isSuccess) {
        const {
          domains,
          email,
          emailNotification,
          generatedScript,
          insertScript,
          message,
          redirectUrl,
          scriptCreated,
          shop,
          shopId,
        } = data.appSettings;
        setAppSettings({
          domains: domains ? domains : "",
          email: email ? email : "",
          emailNotification,
          generatedScript,
          insertScript,
          message,
          redirectUrl: redirectUrl ? fixRedirectUrl(redirectUrl) : "",
          scriptCreated,
          shop,
          shopId,
          themes: data.themes,
          requestedId: -1,
        });
        // make those cute options for the <Select /> element
        createThemeOpts(data.themes);
        setIsLoading(false);
        setIsSuccess(true);
        setIsVerified(true);
        setIsEnabled(true);
      } else {
        // handle verify errors
        setIsLoading(false);
        setIsSuccess(false);
        setIsVerified(false);
        setIsEnabled(false);
        setErrorCode(data.errorCode);
        setUnverifiedRedirectUrl(data.redirectUrl);
      }
    });
    // eslint-disable-next-line
  }, []);

  const submitData = () => {
    const {
      domains,
      email,
      emailNotification,
      insertScript,
      redirectUrl,
      requestedId,
      shop,
    } = appSettings;
    const url =
      "https://scrapeshield.marketagility.com/api/savesettings?shop=" + shop;

    const dataModel = {
      domains,
      email,
      emailNotification,
      insertScript,
      redirectUrl: fixRedirectUrl(redirectUrl),
      requestedId: parseInt(requestedId, 10),
      shop,
    };

    // console.log("Submit this: ", dataModel);

    setIsLoading(true);

    const postSuccess = async response => {
      if (response.ok) {
        setSubmitSuccess(true);
        setSubmitFail(false);
        const { settings, themes } = await response.json();
        // console.log(settings, themes);
        setAppSettings({
          ...appSettings,
          generatedScript: settings.generatedScript,
          insertScript: "auto",
          redirectUrl: fixRedirectUrl(redirectUrl),
          scriptCreated: settings.scriptCreated,
          shopId: settings.shopId,
          themes,
        });
      } else {
        setSubmitSuccess(false);
        setSubmitFail(true);
      }
    };

    const postFail = error => {
      console.error("post failed: ", error);
      setSubmitSuccess(false);
      setSubmitFail(true);
    };

    const postData = () => {
      return fetch(url, {
        cache: "no-cache",
        credentials: "omit",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataModel),
      });
    };

    postData()
      .then(postSuccess)
      .catch(postFail)
      .finally(() => {
        setIsLoading(false);
        window.scrollTo({ top: 0, left: 0 });
      });
  };

  const handleChange = useCallback(
    (newValue, setting) => {
      setAppSettings({ ...appSettings, [setting]: newValue });
    },
    [appSettings]
  );

  const handleSubmit = evt => {
    evt.preventDefault();
    setSubmitFail(false);
    setSubmitSuccess(false);
    submitData();
  };

  const handleDismissBanner = () => {
    setSubmitSuccess(false);
    setSubmitFail(false);
    setSubmitErrorMessage(
      `Failed to submit form: please double check your info and try again.`
    );
  };

  const toggleIntro = () => {
    setShowIntro(!showIntro);
  };

  const toggleModal = () => {
    setModalIsActve(!modalIsActive);
    const msg = modalIsActive ? "" : supportMessage;
    setSupportMessage(msg);
  };

  const onSupportSubmit = () => {
    const url =
      "https://scrapeshield.marketagility.com/api/support?shop=" +
      appSettings.shop +
      "&message=" +
      supportMessage;
    const postSuccess = response => {
      if (response.ok) {
        setSupportSubmitted(true);
        setTimeout(() => {
          setSupportSubmitted(false);
          setSupportMessage("");
          setModalIsActve(false);
          setSupportError("");
        }, 1500);
      }
    };
    const postFail = error => {
      console.error(error);
      setSupportError(error);
    };
    const postSupport = () => {
      return fetch(url, {
        cache: "no-cache",
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
        },
      });
    };

    if (supportMessage.trim().length < 1) {
      setSupportError("Please enter a message.");
    } else {
      setSupportError("");
      postSupport().then(postSuccess).catch(postFail);
    }
  };

  let notificationBanner = submitSuccess ? (
    <NotificationBanner
      handleOnDismiss={handleDismissBanner}
      bannerMessage="Scrape Shield settings have been saved!"
      bannerType="success"
    />
  ) : (
    ""
  );

  if (submitFail && submitErrorMessage) {
    notificationBanner = (
      <NotificationBanner
        handleOnDismiss={handleDismissBanner}
        bannerMessage={submitErrorMessage}
        bannerType="error"
      />
    );
  }

  const generatedScriptEl = appSettings.generatedScript ? (
    <div style={{ padding: "1rem 0" }}>
      <h3
        style={{
          borderTop: ".01rem solid #ccc",
          fontSize: "1.1rem",
          fontWeight: 500,
          lineHeight: "calc(4/3)",
          margin: ".5rem 0",
          padding: "1rem 0 0",
        }}
      >
        Below you will find the script for your site. It will be automatically
        inserted into the &lt;head&gt;&lt;/head&gt; section just before the
        closing &lt;/head&gt; tag in the theme.liquid file in your shop's
        layouts folder. If you do not see it there or if you need to make
        changes, you can copy and paste the snippet below into that location in
        the theme.liquid file.
      </h3>
      <div
        id="hiddenScript"
        style={{ display: "none", height: 0, clip: "rect(0,0,0,0)" }}
      >
        {appSettings.generatedScript}
      </div>
      <pre style={{ overflow: "auto", paddingBottom: "1rem" }}>
        {appSettings.generatedScript}
      </pre>
    </div>
  ) : (
    <h3 style={{ margin: "0 0 .5rem 0", fontSize: "1.2rem", fontWeight: 500 }}>
      Script has not been generated yet!
    </h3>
  );

  const themeSelectorEl = (
    <FormLayout>
      <Select
        label="Into which theme would you like to insert the script?"
        value={parseInt(appSettings.requestedId, 10)}
        onChange={handleChange}
        options={themeOpts}
        id="requestedId"
      />
    </FormLayout>
  );

  const noDataComponent =
    !isLoading && !isSuccess ? (
      <Unverified
        errorCode={errorCode}
        url={unverifiedRedirectUrl}
        appName="Scrape Shield"
      />
    ) : (
      <SkeletonPage primaryAction={false} title={false}>
        <Layout.AnnotatedSection
          title={<SkeletonDisplayText />}
          description={<SkeletonBodyText />}
        >
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.AnnotatedSection>
      </SkeletonPage>
    );

  const submitSection = isLoading ? (
    <Spinner accessibilityLabel="saving settings" size="large" />
  ) : (
    <p>
      Click the button below to save your settings and install/update the Scrape
      Shield script on your site.
    </p>
  );

  const currentScreen = showIntro ? (
    <Intro clickHandler={toggleIntro} />
  ) : (
    <Layout.AnnotatedSection
      fullWidth
      title="Configure Scrape Shield"
      description="Set up Scrape Shield to protect your site"
    >
      <Card sectioned title="Allowed Domains (comma separated)">
        <p style={{ marginBottom: "2rem" }}>
          You do not need to include your shop's custom domain nor your shop's
          myshopify.com domain. Those will be included automatically.
        </p>
        <FormLayout>
          <TextField
            multiline={4}
            error=""
            name="domains"
            id="domains"
            label="Domains"
            onChange={handleChange}
            type="text"
            value={appSettings.domains}
            placeholder="myshop.com, myothershop.com"
          />
        </FormLayout>
      </Card>
      <Card sectioned title="Redirect URL">
        <p style={{ marginBottom: "2rem" }}>
          This is the URL we will redirect visitors to from the scraped site. It
          is almost always going to be the URL of your Shopify shop. Include
          "https://" in the URL you enter below.
        </p>
        <FormLayout>
          <TextField
            error=""
            name="redirectUrl"
            id="redirectUrl"
            label="Redirect URL"
            onChange={handleChange}
            type="text"
            value={appSettings.redirectUrl}
            placeholder="https://myshopifyshop.com"
          />
        </FormLayout>
      </Card>
      <Card sectioned title="Email notification">
        <p style={{ marginBottom: "2rem" }}>
          Notifications will be sent once for every domain that attempts to
          scrape your site.
        </p>
        <FormLayout>
          <Checkbox
            label="Notify me when there's an attempt to scrape my site"
            checked={appSettings.emailNotification}
            id="emailNotification"
            onChange={handleChange}
            name="emailNotification"
          />
          <TextField
            error=""
            name="email"
            id="email"
            label="Email address"
            onChange={handleChange}
            type="email"
            value={appSettings.email}
            placeholder="you@yourdomain.com"
          />
        </FormLayout>
      </Card>
      <Card sectioned>
        {themeSelectorEl}
        {/*<FormLayout>
          <Stack vertical>
            <RadioButton
              label="Install the generated script into my theme.liquid file for me."
              helpText="This option will create a backup of the theme.liquid file in your selected theme named theme.backup-scrapeshield.liquid. The generated script will be inserted into the theme.liquid file of your selected theme."
              checked={appSettings.insertScript === "auto"}
              id="auto"
              onChange={handleChange}
            />
            {themeSelectorEl}
            <RadioButton
              label="I will install the generated script into my theme.liquid file myself."
              helpText="Choosing this option will allow you to copy and paste the script into whichever theme you want whenever you are ready."
              checked={
                appSettings.insertScript === "manual" ||
                !appSettings.insertScript
              }
              id="manual"
              onChange={handleChange}
            />
          </Stack>
            </FormLayout>*/}
        {generatedScriptEl}
      </Card>
      <Card sectioned>
        <FormLayout>
          {submitSection}
          <Button disabled={!isEnabled} primary onClick={handleSubmit}>
            Save Settings
          </Button>
        </FormLayout>
      </Card>
    </Layout.AnnotatedSection>
  );

  // console.log("appSettings: ", appSettings);

  return isVerified ? (
    <Page
      title="Scrape Shield"
      secondaryActions={
        <Button monochrome onClick={toggleIntro}>
          {showIntro ? "Back to the app" : "Getting Started"}
        </Button>
      }
    >
      <Layout>
        <ContactModal
          toggleModal={toggleModal}
          supportSubmit={onSupportSubmit}
          modalActive={modalIsActive}
          supportSubmitted={supportSubmitted}
          updateSupportMessage={newVal => {
            setSupportMessage(newVal);
          }}
          supportMessage={supportMessage}
          supportError={supportError}
        />
        {notificationBanner}
        {currentScreen}
        <Layout.Section>
          <FooterHelp>
            Need help?{" "}
            <Button plain onClick={toggleModal}>
              Contact us
            </Button>
          </FooterHelp>
        </Layout.Section>
      </Layout>
    </Page>
  ) : (
    noDataComponent
  );
};

export default ScrapeShield;
