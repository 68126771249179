import Cookies from "js-cookie";
import fetch from "isomorphic-fetch";
export default async function getInitialData(appUrl, appMaUrl, credentials = true) {
  const searchParams = new URL(document.location).searchParams;
  let shop = searchParams.get("shop");
  if (!shop) {
    shop = Cookies.get("shopOrigin");
    if (shop) {
      Cookies.set("shopOrigin", shop);
    } else {
      document.location.href = "/" + appUrl + "/error?message=no%20shop";
    }
  }
  const response = await fetch(appMaUrl + "/api/verify?shop=" + shop, {
    cache: "no-cache",
    credentials: credentials ? "include" : "omit",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  if (data.errorCode === "NOT_INSTALLED") {
    const installLink = appMaUrl + "/api/install?shop=" + shop;
    window.location.href = installLink;
  }
  else {
    return data;
  }
}

// export async function getInitialDataTest(appUrl, appMaUrl, credentials = true) {
//   const searchParams = new URL(document.location).searchParams;
//   let shop = searchParams.get("shop");
//   if (!shop) {
//     shop = Cookies.get("shopOrigin");
//     if (shop) {
//       Cookies.set("shopOrigin", shop);
//     } else {
//       document.location.href = "/" + appUrl + "/error?message=no%20shop";
//     }
//   }
//   const response = await fetch(appMaUrl + "/api/verify?shop=" + shop, {
//     cache: "no-cache",
//     credentials: credentials ? "include" : "omit",
//     headers: {
//       "Content-Type": "application/json",
//     },
//   });
//   console.log(response);
//   const data = await response.json();
//   console.log(data);
//   if (response.status !== 200 || data.errorCode === "NOT_INSTALLED") {
//     const installLink = appMaUrl + "/api/install?shop=" + shop;
//     window.location.href = installLink;
//   }
//   else {
//     return data;
//   }
// }