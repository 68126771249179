import React, { Component } from "react";
import { Banner, Layout } from "@shopify/polaris";
import {
  CircleAlertMajor,
  ChecklistMajor,
} from "@shopify/polaris-icons";
import PropTypes from "prop-types";

export default class FailureBanner extends Component {
  render() {
    let bannerIcon = CircleAlertMajor,
      bannerStatus = "critical",
      bannerTitle = "Error";
    if (this.props.bannerType === "success") {
      bannerIcon = ChecklistMajor;
      bannerStatus = "success";
      bannerTitle = "Success";
    }
    return (
      <Layout.Section>
        <Banner
          icon={bannerIcon}
          status={bannerStatus}
          title={bannerTitle}
          onDismiss={this.props.handleOnDismiss}
        >
          <p>{this.props.bannerMessage}</p>
        </Banner>
      </Layout.Section>
    );
  }
}

FailureBanner.propTypes = {
  handleOnDismiss: PropTypes.func.isRequired,
  bannerMessage: PropTypes.string.isRequired,
  bannerType: PropTypes.string.isRequired,
};
