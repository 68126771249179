import React, { Component } from "react";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { AppProvider } from "@shopify/polaris";
import "@shopify/polaris/build/esm/styles.css";
import translations from "@shopify/polaris/locales/en.json";
import ErrorPage from "./components/generic-error";

import IndexPage from "./index-page";

import ImageAlertApp from "./shopify-apps/image-alert/index";

import ProductCheckupApp from "./shopify-apps/product-checkup/index";

import ShareASaleApp from "./shopify-apps/shareasale/index";
import ShareASaleIntro from "./shopify-apps/shareasale/intro";

import ScrapeShield from "./shopify-apps/scrape-shield/index";
import ScrapeShieldIntro from "./shopify-apps/scrape-shield/intro";


const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");

export default class App extends Component {
  render() {
    return (
      <AppProvider i18n={translations}>
        <BrowserRouter basename={baseUrl}>
          <Routes>
            <Route
              exact
              path="/image-alert-app/error"
              element={<ErrorPage appName="Image Alert" />}
            />
            <Route exact path="/image-alert-app" element={<ImageAlertApp />} />
            <Route
              exact
              path="/product-checkup-app/error"
              element={<ErrorPage appName="Product Checkup" />}
            />
            <Route
              exact
              path="/product-checkup-app"
              element={<ProductCheckupApp />}
            />
            <Route
              exact
              path="/shareasale-app/error"
              element={<ErrorPage appName="ShareASale Export" />}
            />
            <Route
              exact
              path="/shareasale-app/intro"
              element={<ShareASaleIntro />}
            />
            <Route exact path="/shareasale-app" element={<ShareASaleApp />} />
            <Route
              exact
              path="/scrapeshield-app/error"
              element={<ErrorPage appName="ScrapeShield" />}
            />
            <Route
              exact
              path="/scrapeshield-app/intro"
              element={<ScrapeShieldIntro />}
            />
            <Route exact path="/scrapeshield-app" element={<ScrapeShield />} />
            <Route exact path="/" element={<IndexPage />} />
          </Routes>
        </BrowserRouter>
      </AppProvider>
    );
  }
}
