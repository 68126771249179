import React, { Component } from "react";
import Cookies from "js-cookie";
import fetch from "isomorphic-fetch";
import {
  Banner,
  Button,
  Card,
  Checkbox,
  FooterHelp,
  FormLayout,
  Layout,
  Page,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  TextField,
} from "@shopify/polaris";
import {
  ChecklistMajor,
  CircleAlertMajor,
} from "@shopify/polaris-icons";

import ContactModal from "../../components/contact-modal";

const hours = [],
  minutes = [];

for (let i = 0; i < 24; i++) {
  hours.push(i < 10 ? "0" + i : "" + i);
}
for (let i = 0; i < 60; i++) {
  minutes.push(i < 10 ? "0" + i : "" + i);
}

export default class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: {
        value: "",
        error: "",
      },
      missingTag: {
        value: "",
        error: "",
      },
      runDaily: false,
      submitSuccess: false,
      submitFail: false,
      submitErrorMessage:
        "Failed to submit form: please double check your info and try again.",
      unpublish: false,
      verified: false,
      modalActive: false,
      supportMessage: "",
      supportSubmitted: false,
    };
  }

  componentDidMount() {
    let shop = Cookies.get("shopOrigin");
    if (!shop) {
      const searchParams = new URL(document.location).searchParams;
      shop = searchParams.get("shop");
      if (shop) {
        Cookies.set("shopOrigin", shop);
        this.setState({ verified: true, shop: shop });
      } else {
        document.location.href = "/image-alert-app/error?message=no%20shop";
      }
    }
    fetch("https://imagealert.marketagility.com/api/verify?shop=" + shop, {
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 404) {
          const installLink =
            "https://imagealert.marketagility.com/api/install?shop=" + shop;
          document.location.href = installLink;
        } else if (response.status === 200) {
          return response.json();
        }
      })
      .then((data) => {
        this.setState({
          ...this.state,
          email: { ...this.state.email, value: data.email },
          runDaily: data.rundaily,
          runHour: data.runhour,
          runMinutes: data.runminutes,
          missingTag: { ...this.state.missingTag, value: data.tagname },
          shop: data.shop,
          verified: true,
          unpublish: data.unpublish,
        });
      });
  }

  handleChange = (field) => {
    return (newValue) => {
      this.setState({
        ...this.state,
        [field]: {
          value: newValue,
        },
      });
    };
  };

  handleUnpublishChange = (newChecked) => {
    this.setState({ ...this.state, unpublish: newChecked });
  };

  handleRunDailyChange = (newChecked) => {
    this.setState({ ...this.state, runDaily: newChecked });
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    let errors = 0;
    const { missingTag, email } = this.state;
    this.setState({
      ...this.state,
      submitFail: false,
      submitSuccess: false,
    });
    if (missingTag.value.length < 1) {
      errors++;
      this.setState({
        ...this.state,
        missingTag: { ...missingTag, error: "Tag is required" },
        submitFail: true,
        submitSuccess: false,
      });
    }
    if (email.value.length < 1) {
      errors++;
      this.setState({
        ...this.state,
        email: { ...email, error: "Email address is required" },
        submitFail: true,
        submitSuccess: false,
      });
    }
    if (errors < 1) {
      this.submitData();
    }
  };

  submitData = () => {
    const { email, missingTag, runDaily, shop, unpublish } = this.state;
    const runHour = runDaily ? this.state.runHour : "";
    const runMinutes = runDaily ? this.state.runMinutes : "";

    const url =
      "https://imagealert.marketagility.com/api/run?shop=" +
      shop +
      "&email=" +
      email.value +
      "&tagname=" +
      missingTag.value +
      "&unpublish=" +
      unpublish +
      "&rundaily=" +
      runDaily +
      "&runhour=" +
      runHour +
      "&runminutes=" +
      runMinutes;

    const postSuccess = (response) => {
      if (response.ok) {
        this.setState({
          submitSuccess: true,
          submitFail: false,
        });
      } else {
        this.setState({
          submitSuccess: false,
          submitFail: true,
        });
      }
    };

    const postFail = (error) => {
      console.error("post failed: ", error);
      this.setState({
        submitSuccess: false,
        submitFail: true,
      });
    };

    const postData = () => {
      return fetch(url, {
        cache: "no-cache",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
    };

    postData().then(postSuccess).catch(postFail);
  };

  handleChangeTime = (newValue, field) => {
    this.setState({
      ...this.state,
      [field]: newValue,
    });
  };

  toggleModal = () => {
    this.setState({
      ...this.state,
      modalActive: !this.state.modalActive,
      supportMessage: this.state.modalActive ? "" : this.state.supportMessage,
    });
  };

  onSupportSubmit = () => {
    const { supportMessage, shop } = this.state;
    const url =
      "https://productcheckup.marketagility.com/api/support?shop=" +
      shop +
      "&message=" +
      supportMessage;
    const postSuccess = (response) => {
      if (response.ok) {
        this.setState({
          ...this.state,
          supportSubmitted: true,
        });
        setTimeout(() => {
          this.setState({
            ...this.state,
            supportSubmitted: false,
            supportMessage: "",
            modalActive: false,
            supportError: "",
          });
        }, 1500);
      }
    };
    const postFail = (error) => {
      console.error(error);
      this.setState({
        ...this.state,
        supportError: error,
      });
    };
    const postSupport = () => {
      return fetch(url, {
        cache: "no-cache",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
    };

    if (supportMessage.trim().length < 1) {
      this.setState({
        ...this.state,
        supportError: "Please enter a message",
      });
    } else {
      this.setState({
        ...this.state,
        supportError: "",
      });
      postSupport().then(postSuccess).catch(postFail);
    }
  };

  render() {
    const {
      email,
      missingTag,
      runDaily,
      runHour,
      runMinutes,
      submitSuccess,
      submitFail,
      submitErrorMessage,
      unpublish,
      verified,
      modalActive,
      supportMessage,
      supportSubmitted,
      supportError,
    } = this.state;
    const successBanner = submitSuccess ? (
      <Layout.Section>
        <Banner
          icon={ChecklistMajor}
          status="success"
          title="It worked!"
          onDismiss={() => {
            this.setState({
              submitSuccess: false,
              submitFail: false,
            });
          }}
        >
          <p>
            Your products will get tagged and you will receive an email when the
            updates are complete!
          </p>
        </Banner>
      </Layout.Section>
    ) : (
      ""
    );
    const failureBanner =
      submitFail && submitErrorMessage ? (
        <Layout.Section>
          <Banner
            icon={CircleAlertMajor}
            status="critical"
            title="Error"
            onDismiss={() => {
              this.setState({ submitSuccess: false, submitFail: false });
            }}
          >
            <p>{submitErrorMessage}</p>
          </Banner>
        </Layout.Section>
      ) : (
        ""
      );
    return verified ? (
      <Page>
        <Layout>
          <ContactModal
            toggleModal={this.toggleModal}
            supportSubmit={this.onSupportSubmit}
            modalActive={modalActive}
            supportSubmitted={supportSubmitted}
            updateSupportMessage={(newVal) => {
              this.setState({
                ...this.state,
                supportMessage: newVal,
              });
            }}
            supportMessage={supportMessage}
            supportError={supportError}
          />
          {successBanner}
          {failureBanner}
          <Layout.AnnotatedSection
            title="Configure Image Alert"
            description="What would you like this alert to do?"
          >
            <Card sectioned>
              <FormLayout>
                <TextField
                  error={missingTag.error}
                  id="TextMissingTag"
                  label="What tag would you like to apply to products with missing images?"
                  onChange={this.handleChange("missingTag")}
                  placeholder="missing-image"
                  type="text"
                  value={missingTag.value}
                />
                <TextField
                  error={email.error}
                  id="TextEmailAddress"
                  label="What email should we notify when the process is complete?"
                  onChange={this.handleChange("email")}
                  placeholder="username@email.com"
                  type="email"
                  value={email.value}
                />
                <Checkbox
                  label="Unpublish items that don't have an image"
                  checked={unpublish}
                  onChange={this.handleUnpublishChange}
                />
                <Checkbox
                  label="Run daily"
                  checked={runDaily}
                  onChange={this.handleRunDailyChange}
                />
                {runDaily ? (
                  <>
                    <TextContainer>At what time?</TextContainer>
                    <FormLayout.Group>
                      <Select
                        label="Hour"
                        options={hours}
                        onChange={(val) => {
                          this.handleChangeTime(val, "runHour");
                        }}
                        value={runHour}
                      />
                      <Select
                        label="Minute"
                        options={minutes}
                        onChange={(val) => {
                          this.handleChangeTime(val, "runMinutes");
                        }}
                        value={runMinutes}
                      />
                    </FormLayout.Group>
                  </>
                ) : (
                  ""
                )}
                <Button primary onClick={this.handleSubmit}>
                  Run
                </Button>
              </FormLayout>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.Section>
            <FooterHelp>
              Need help?{" "}
              <Button plain onClick={this.toggleModal}>
                Contact us
              </Button>
            </FooterHelp>
          </Layout.Section>
        </Layout>
      </Page>
    ) : (
      <SkeletonPage primaryAction={false} title={false}>
        <Layout.AnnotatedSection
          title={<SkeletonDisplayText />}
          description={<SkeletonBodyText />}
        >
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </Card>
        </Layout.AnnotatedSection>
      </SkeletonPage>
    );
  }
}
