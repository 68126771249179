import React from "react";
import { Card, Layout, List, TextContainer } from "@shopify/polaris";

const Intro = (props) => {
  const startString = `<!-- scrapeshield_app_script_begin --><script type="text/javascript" id="scrapeshield_app_script">`;
  const endString = `</script><!-- scrapeshield_app_script_end -->`;
  return (
    <Layout.Section>
      <Card>
        <Card.Header title="1) Add Allowed Domains"></Card.Header>
        <Card.Section>
          <p>
            If there are any domains you want to allow to use your site content,
            enter them to prevent users from being redirected. If you are
            allowing multiple domains, separate each domain by a comma. This
            field can be blank, and in a lot of cases it will be.
          </p>
        </Card.Section>
        <Card.Header title="2) Add a Redirect URL"></Card.Header>
        <Card.Section>
          <p>
            When someone attempts to scrape your site they will be redirected to
            this URL. Except in very few cases this will be the URL to your
            shop.
          </p>
        </Card.Section>
        <Card.Header title="3) Email Notifications"></Card.Header>
        <Card.Section>
          <p>
            Selecting this option will notify you of attempts to scrape your
            site. You will only be notified once per attempt from each domain.
            Enter a valid email to receive notifcations. The notification will
            include the domain that attempted to scrape your site, and the time
            and date of the attempt.
          </p>
        </Card.Section>
        <Card.Header title="4) Installing the Script"></Card.Header>
        <Card.Section>
          <p>
            You can choose to install the script to block site scrapers, or have
            it automatically installed into one of your themes. Users who are
            comfortable editing their theme files will be able to add the script
            with minimal effort. If you are not comfortable editing theme files
            the script can get installed for you. When inserting it
            automatically you can choose the theme you want it installed on and
            the app will install the script.
          </p>
        </Card.Section>
        <Card.Header title="5) Save your Settings"></Card.Header>
        <Card.Section>
          <TextContainer spacing="loose">
            <p>
              Save your settings after making your changes. If a script has not
              been generated yet, it will be. If a script has already been
              generated, a new one will be created to replace the old one. You
              can choose to install the new one yourself or let the app install
              it for you.
            </p>
          </TextContainer>
        </Card.Section>
        <Card.Header title="6) Test It Out"></Card.Header>
        <Card.Section>
          <TextContainer spacing="loose">
            <p>
              Because of the nature of the app you won't be able to tell
              immediately by looking at your site if it is installed and working
              properly. With a little bit of technical effort you{" "}
              <strong>CAN</strong> see it working.
            </p>
            <List type="number">
              <List.Item>Copy the generated script.</List.Item>
              <List.Item>
                Open a new browser tab, and navigate to a domain that is not on
                your allowed domains list (say google.com).
              </List.Item>
              <List.Item>
                Open the Developer Tools in the new tab. For Google Chrome the
                keyboard shortcut for CTRL+SHIFT+I, or you can click the three
                vertical dots in the top right of the browser, go to "More
                tools" and click "Developer tools" at the bottom of that list.
                Other browsers use similar language, but slightly different ways
                to open the developer tools.
              </List.Item>
              <List.Item>
                Click the "Console" tab in the developer console that you opened
                (again using Chrome as an example, other browsers may use
                different language). Click inside the console area, and paste
                the generated script you copied (if right-clicking and pasting
                isn't available use the keyboard shortcut CTRL+V to paste).
              </List.Item>
              <List.Item>
                Delete the following from the beginning of the text you pasted:
                <br />
                <pre>{startString}</pre>
                <br />
                And the following from the end of the text you pasted:
                <pre>{endString}</pre>
              </List.Item>
              <List.Item>
                Hit enter to run the script. Your browser tab should redirect
                you to the URL you entered for the Redirect URL field.
              </List.Item>
            </List>
          </TextContainer>
        </Card.Section>
      </Card>
    </Layout.Section>
  );
};

export default Intro;
